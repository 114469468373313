import {
    MetaActionType,
} from 'Store/Meta/Meta.type';

/** @namespace Satisfly/Store/Meta/Reducer/getInitialState */
export const getInitialState = () => ({
    title: '',
    title_prefix: '',
    title_suffix: '',
    description: '',
    keywords: '',
    canonical_url: '',
    robots: '',
    status_code: '',
});

/** @namespace Satisfly/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action,
) => {
    const { payload = {}, type } = action;

    switch (type) {
    case MetaActionType.UPDATE_META:
        return {
            ...state,
            ...payload,
        };

    default:
        return state;
    }
};

export default MetaReducer;
